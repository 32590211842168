import React from 'react';
import { Link } from 'react-router-dom';

import PartHeader from '../../components/part-header/PartHeader';
import PartFooter from '../../components/part-footer/PartFooter';
import wave from '../../assets/wave/wave.svg';
import './A.scss';
import Card from '../../components/card/Card';

import math from '../../assets/math_illustration.svg';
import music from '../../assets/illustrations/music_illustration.svg';

const A = () => {
  return (
    <div>
      <PartHeader title="A-Flügel" parental_url="/" />
      <div className="waveWrapper">
        <div className="wave">
          <img src={wave} alt="waveline" />
          <Link to="/subject?_subject=math">
            <div className="cardContainer card1">
              <Card
                text="Mathematik"
                image={math}
                alt="Bild über Mathematik"
                parent_url="/a"
              />
            </div>
          </Link>
          <Link to="/subject?_subject=music">
            <div className="cardContainer card2">
              <Card
                text="Musik"
                image={music}
                alt="Bild über Musik"
                parent_url="/a"
              />
            </div>
          </Link>
        </div>
      </div>
      <PartFooter parental_url="/" />
    </div>
  );
};

export default A;
