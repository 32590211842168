import interact from 'interactjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import gsap from 'gsap';

import Card from 'components/card/Card';
import PartHeader from 'components/part-header/PartHeader';

import subjects from 'data/subjects.json';

import './Subject.scss';

const dragMoveListener = (event) => {
  var target = event.target;

  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
};

const Subject = (props) => {
  const [searchParams] = useSearchParams();

  const [contentShown, setContentShown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [content, setContent] = useState();

  const subject = subjects[searchParams.get('_subject')];

  useEffect(() => {
    interact('.draggable')
      .draggable({
        inertia: true,

        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],

        autoScroll: true,

        listeners: {
          move: dragMoveListener,
          start: () => {
            setDragging(true);
            console.log('start');
          },
          end: () => {
            setTimeout(() => {
              setDragging(false);
              console.log('end');
            }, 100);
          },
        },
      })
      .styleCursor(false);
  });

  useEffect(() => {
    gsap
      .timeline({ defaults: { duration: 1.5 } })
      .to('#card', { opacity: 0 }, 0.5)
      .set('#card', { display: 'none' });
  });

  return (
    <>
      <div className="subjectWrapper fullViewport">
        <PartHeader parental_url="/a" title=""></PartHeader>
        <div className="cardWrapper" id="card">
          <Card
            text={subject.name}
            image={require('assets/' + subject.picturePath).default}
            alt={subject.pictureAlt}
          ></Card>
        </div>
        {subject.symbols.map((symbol) => {
          return (
            <img
              onClick={() => {
                if (!dragging) {
                  setContent(symbol.content);
                  console.log(content);
                  setContentShown(true);
                }
              }}
              className="symbol"
              src={require('assets/' + symbol.file).default}
              alt={symbol.name}
              key={symbol.name}
              style={{
                top: Math.floor(
                  Math.random() * (window.innerHeight - 120) + 50
                ),
                left: Math.floor(
                  Math.random() * (window.innerWidth - 120) + 50
                ),
              }}
            />
          );
        })}
      </div>
      {contentShown && (
        <div className="contentWrapper fullViewport">
          <PartHeader
            type="exit"
            exitfun={setContentShown.bind(false)}
            title={content.title}
          ></PartHeader>
          {content.type === 'video' ? (
            <div className="fullViewport videoWrapper">
              <video
                controls
                style={{
                  maxHeight: window.innerHeight - 100 + 'px',
                  maxWidth: window.innerWidth,
                }}
              >
                <source
                  src={require('assets/' + content.file).default}
                  type="video/mp4"
                />
                Your browser supports neither OGG nor MP4 videos.
              </video>
            </div>
          ) : (
            <div
              style={{ maxHeight: window.innerHeight - 56 + 'px' }}
              className="text"
              dangerouslySetInnerHTML={{ __html: content.text }}
            ></div>
          )}
        </div>
      )}
    </>
  );
};

export default Subject;
