import { Link } from 'react-router-dom';

import './PartFooter.scss';

import back from '../../assets/controls/back.svg';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const PartFooter = (props) => {
  return (
    <div className="footerWrapper">
      <Link to={props.parental_url}>
        <img src={back} alt="Zurück" className="back" />
      </Link>
      <img src={back} alt="zum Anfang" className="top" onClick={scrollToTop} />
    </div>
  );
};

export default PartFooter;
