import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { persistentAtom } from 'recoil-persistence/react-native';
import { useRecoilState } from 'recoil';

import './Home.scss';

import '../../fonts/Fonts.css';
import layout from '../../assets/layout.svg';
import logo from 'assets/fhg-logo.svg';

const persistentS = persistentAtom({
  key: 'persistentS',
  default: null,
});

const persistentP = persistentAtom({
  key: 'persistentP',
  default: null,
});

const Home = () => {
  const [btnSize, setBtnSize] = useRecoilState(persistentS);
  const [btnAPos, setBtnAPos] = useRecoilState(persistentP);

  const imageSize = useRef(null);

  useEffect(() => {
    console.log(btnSize);
    if (btnSize === null) {
      console.log('btn', null);
      if (imageSize.current) {
        setBtnSize({
          width: imageSize.current.offsetWidth / 5,
          height: imageSize.current.offsetHeight / 8,
        });
      }

      console.log('btnsize', imageSize.current, btnSize);
    }
    if (btnAPos === null) {
      setBtnAPos({
        left: (window.innerWidth - imageSize.current.offsetWidth) / 2 + 10,
        top:
          (window.innerHeight - imageSize.current.offsetHeight) / 2 +
          window.innerHeight +
          10,
      });
    }
  });

  return (
    <div className="wrapper">
      <div className="welcome-wrapper">
        <div>
          <img src={logo} alt="Logo" />
          <p className="heading">Fritz Hoffmann Gymnasium</p>
          <p className="subheading">Kölleda</p>
        </div>
      </div>
      <div className="layout-wrapper">
        <img src={layout} alt="Layout of school" ref={imageSize} />
        <div className="a-button" style={btnAPos}>
          <Link to="/a">
            <button style={btnSize} className="button">
              A[POS]
            </button>
          </Link>
          <Link to="/a">
            <button
              style={{
                top: '50px',
                left: '50px',
              }}
            >
              A
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
