import React from 'react';

import '../../fonts/Fonts.css';
import './Card.scss';

const Card = (props) => {
  return (
    <div className="cardWrapper">
      <div className="cardImageContainer">
        <img className="cardImage" src={props.image} alt={props.alt} />
      </div>
      <div className="cardText">{props.text}</div>
    </div>
  );
};

export default Card;
