import { Link } from 'react-router-dom';

import './PartHeader.scss';
import '../../fonts/Fonts.css';

import back from '../../assets/controls/back.svg';
import exit from 'assets/controls/exit.svg';

const PartHeader = (props) => {
  return (
    <div className="headerWrapper">
      {props.type === 'exit' ? (
        <img
          src={exit}
          alt="Exit"
          className="back"
          onClick={() => {
            props.exitfun();
          }}
        />
      ) : (
        <Link to={props.parental_url}>
          <img src={back} alt="Zurück" className="back" />
        </Link>
      )}
      <div className="title">{props.title}</div>
    </div>
  );
};

export default PartHeader;
