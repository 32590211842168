import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as RRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

// Hooks
import ScrollToTopHook from 'hooks/ScrollToTopHook';

// Pages
import Home from 'pages/home/Home';
import A from 'pages/a/A';
import Subject from 'pages/subject/Subject';

const Router = () => {
  const [isLoading, setIsLoading] = useState(true);

  const cacheImg = async (images) => {
    const promises = await images.map((image) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = require('assets/' + image);
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
  };

  useEffect(() => {
    const files = ['layout.svg'];
    cacheImg(files);
  }, []);

  return (
    <RRouter>
      <RecoilRoot>
        <Suspense fallback={<span>Loading...</span>}>
          <ScrollToTopHook>
            {isLoading ? (
              <div>Loading</div>
            ) : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/a" element={<A />} />

                <Route path="/subject" element={<Subject />} />
              </Routes>
            )}
          </ScrollToTopHook>
        </Suspense>
      </RecoilRoot>
    </RRouter>
  );
};

export default Router;
